<template>
    <div class="container">
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="icon-big" fill-rule="evenodd" clip-rule="evenodd" d="M60 19.25C37.4944 19.25 19.25 37.4944 19.25 60C19.25 82.5056 37.4944 100.75 60 100.75C73.3242 100.75 85.1544 94.365 92.6047 84.4486C95.6733 80.3643 101.472 79.5409 105.556 82.6096C109.641 85.6782 110.464 91.4768 107.395 95.5611C96.6011 109.928 79.3878 119.25 60 119.25C27.2771 119.25 0.75 92.7229 0.75 60C0.75 27.2771 27.2771 0.75 60 0.75C79.3904 0.75 96.6057 10.0743 107.4 24.4447C110.468 28.5293 109.644 34.3279 105.559 37.396C101.474 40.4641 95.6758 39.64 92.6077 35.5553C85.1575 25.6367 73.3259 19.25 60 19.25Z" fill="#DE4B4E"/>
            <g class="icon-small" opacity="0.5">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60 40.2083C49.0694 40.2083 40.2083 49.0694 40.2083 60C40.2083 70.9306 49.0694 79.7917 60 79.7917C66.4702 79.7917 72.215 76.6935 75.8365 71.8733C77.9099 69.1137 81.8279 68.5573 84.5876 70.6307C87.3472 72.7041 87.9036 76.6221 85.8302 79.3818C79.9493 87.2092 70.5673 92.2917 60 92.2917C42.1658 92.2917 27.7083 77.8342 27.7083 60C27.7083 42.1658 42.1658 27.7083 60 27.7083C70.5687 27.7083 79.9518 32.7922 85.8325 40.6214C87.9056 43.3813 87.3488 47.2992 84.5889 49.3723C81.8289 51.4454 77.911 50.8885 75.838 48.1286C72.2165 43.3073 66.4711 40.2083 60 40.2083Z" fill="#DE4B4E"/>
            </g>
        </svg>
        <h1>Coming soon...</h1>
    </div>
</template>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
textarea,
input,
button,
p, h1, h2, h3, h4 {
    font-family: 'Montserrat';
}

.container {
    background-color: #1D1D1D;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

h1 {
    font-size: 22px;
    font-weight: normal;
    color: white;
    text-align: center;
    opacity: 0.75;
}

.icon-big {
    animation-name: Icon;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: center center;
}

.icon-small {
    animation-name: Icon;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: center center;
    animation-direction: reverse;
}

@keyframes Icon {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
